<template>
  <ConfirmDialog></ConfirmDialog>
  <Toast />
  <ComprasCreate
    :mostrar="NuevaCompra"
    :sucursales="sucursales"
    :proveedores="proveedores"
    @closeModal="closeModal"
    @actualizarListadoOC="cargarComprasFiltros"
  ></ComprasCreate>
  <!-- @actualizarListadoOC="cargarOrdenesCompras" -->
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Compras</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Listado de Compras </span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div class="font-medium text-2xl text-900">
        <strong>Administrar &Oacute;rdenes de Compras </strong>
        <Badge size="xlarge" severity="success"> {{ totalRecords }}</Badge>
      </div>

      <div class="mt-3 lg:mt-0">
        <Button
          label="NUEVA SOLICITUD OC"
          v-tooltip.top="'Solicitar Nueva Orden de Compra'"
          v-if="'Compra NuevaSolicitud' in auth.user.permissions"
          class="p-button-outlined mr-2 p-button-primary p-button-raised p-button-lg"
          icon="pi pi-plus"
          @click="activarModal"
        ></Button>
        <Button
          label="ORDEN DE COMPRA"
          v-tooltip.top="'Registrar Orden de Compra'"
          v-if="'Compra Crear' in auth.user.permissions"
          class="p-button-outlined mr-2 p-button-success p-button-raised p-button-lg"
          icon="pi pi-plus"
          @click="nuevaCompra"
        ></Button>
      </div>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-2">
            <span><strong>RECIBO/FACTURA: </strong></span>
            <InputNumber
              v-model="nro_recibo"
              placeholder="Nro Recibo"
              :useGrouping="false"
              :class="'text-right'"
              @keyup.enter="BuscarFiltro"
            />
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>PROVEEDOR: </strong></span>
            <Dropdown
              v-model="proveedorSelected"
              :options="proveedores"
              optionLabel="nombre_completo"
              placeholder="Seleccionar..."
              optionValue="id"
              :filter="true"
            />
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>FECHA INICIO: </strong></span>
            <Calendar
              v-model="fecha_inicio"
              :class="'text-right'"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>FECHA FIN: </strong></span>
            <Calendar
              v-model="fecha_fin"
              :class="'text-right'"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>ESTADO: </strong></span>
            <Dropdown
              v-model="estadoSelected"
              :options="estados"
              optionLabel="nombre"
              optionValue="id"
              placeholder="Estados..."
            />
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>ACCIONES: </strong></span>
            <Button
              label="BUSCAR"
              icon="pi pi-search"
              class="p-button-info mr-2"
              v-tooltip.top="'Buscar'"
              @click="BuscarFiltro"
              :loading="loading"
            />
          </div>
        </div>
      </div>
      <div class="mt-2">
        <DataTable
          ref="dt_oc_list"
          dataKey="id"
          class="p-datatable-sm"
          responsiveLayout="scroll"
          stripedRows
          selectionMode="single click"
          showGridlines
          :value="ordenes_compras"
          :loading="loading"
          :rowHover="true"
          @row-dblclick="verDetalle($event.data)"
          :paginator="true"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[20, 50, 100, 500, 1000]"
          currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} registros"
          :rows="rows"
          @page="cambiarPagina"
        >
          <template #loading>
            <div class="flex align-items-center justify-content-center">
              <ProgressSpinner />
            </div>
          </template>
          <template #empty>
            <span
              class="flex align-items-center justify-content-center p-invalid"
              >No existen Registros!</span
            ></template
          >
          <Column field="id" header="ID"></Column>
          <Column field="nro_recibo" header="REC/FAC" :class="'text-right'">
            <template #body="slotProps">
              <span v-if="slotProps.data.nro_recibo">
                {{ slotProps.data.nro_recibo }}
              </span>
              <span v-else>-</span>
            </template>
          </Column>
          <Column field="nombre_user" header="SOLICITADO POR"></Column>
          <Column
            field="nombre_completo"
            header="PROVEEDOR"
            style="font-weight: bold"
          ></Column>
          <Column field="nombre_fabrica" header="LABORATORIO">
            <template #body="{ data }">
              <span
                v-if="data.nombre_fabrica"
                v-tooltip.top="data.nombre_fabrica"
              >
                {{
                  data.nombre_fabrica.length > 20
                    ? data.nombre_fabrica.substring(0, 20) + "..."
                    : data.nombre_fabrica
                }}
              </span>
              <span v-else>-</span>
            </template>
          </Column>
          <Column field="fecha_registro" header="F.SOLICITUD/F.RECEPCION">
            <template #body="{ data }">
              {{
                data.fecha_registro ? data.fecha_registro.split(" ")[0] : "-"
              }}
              -
              {{
                data.fecha_recepcion
                  ? data.fecha_recepcion.split(" ")[0]
                  : "Pendiente"
              }}
            </template>
          </Column>
          <Column field="estado" header="ESTADO">
            <template #body="{ data }">
              <span :class="'product-badge ' + claseEstado[data.estado]">
                {{
                  data.estado == 0
                    ? "ANULADO"
                    : data.estado == 1
                    ? "PENDIENTE"
                    : data.estado == 2
                    ? "REC-PEND."
                    : data.estado == 3
                    ? "REC-PAGADO"
                    : ""
                }}
              </span>
            </template>
          </Column>
          <Column header="ACCIONES">
            <template #body="slotProps">
              <Button
                icon="pi pi-ellipsis-v"
                v-tooltip.top="'Ver Acciones'"
                class="p-button-icon-only p-button-raised p-button-info"
                @click="$refs['menu' + slotProps.data.id].toggle($event)"
              ></Button>
              <Menu
                :ref="'menu' + slotProps.data.id"
                :popup="true"
                :model="acciones(slotProps.data)"
              ></Menu>
            </template>
          </Column>
        </DataTable>
        <PagoProveedor
          :show="pagoProveedorDialog"
          :pago_proveedor="{ ...pago_proveedor }"
          @closeModal="cerrarModalPagoProveedor"
          @actualizarListado="cargarComprasFiltros"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ComprasCreate from "@/module/compras/ComprasCreate.vue";
import OrdenCompraService from "@/service/OrdenCompraService";
import { useAuth } from "@/stores";
import PagoProveedor from "@/module/proveedores/PagoProveedor.vue";
import ProveedorService from "@/service/ProveedorService";

export default {
  name: "Compras",
  components: {
    ComprasCreate,
    PagoProveedor,
  },
  data() {
    return {
      NuevaCompra: false,
      ordenes_compras: [],
      loading: false,
      buscar: null,
      claseEstado: [
        "status-outofstock",
        "status-lowstock",
        "customer-badge status-new",
        "status-instock",
      ],
      pagoProveedorDialog: false,
      pago_proveedor: {},
      totalRecords: 0,
      estados: [
        { nombre: "TODOS", id: 4 },
        { nombre: "ANULADO", id: 0 },
        { nombre: "PENDIENTE", id: 1 },
        { nombre: "RECIBIDO - POR PAGAR", id: 2 },
        { nombre: "RECIBIDO - PAGADO", id: 3 },
      ],
      estadoSelected: 4,
      nro_recibo: null,
      fecha_inicio: null,
      fecha_fin: null,
      proveedores: [],
      proveedorSelected: null,
      rows: 20,
      sucursales: [],
    };
  },
  ordenesCompras: null,
  auth: useAuth(),
  proveedorService: null,

  created() {
    this.auth = useAuth();
    this.ordenesCompras = new OrdenCompraService();
    this.proveedorService = new ProveedorService();
  },
  mounted() {
    this.cargarComprasFiltros();
  },
  methods: {
    cambiarPagina(event) {
      this.rows = event.rows;
      this.cargarComprasFiltros();
    },
    BuscarFiltro() {
      this.ordenes_compras = [];
      this.loading = true;
      this.cargarComprasFiltros();
    },
    cargarComprasFiltros() {
      this.cargarProveedores();
      this.loading = true;
      let datos = {
        nro_recibo: this.nro_recibo,
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
        proveedor_id: this.proveedorSelected,
        estado: this.estadoSelected,
        rows: this.rows,
      };
      this.ordenesCompras
        .getOrdenesComprasFiltros(datos)
        .then((data) => {
          this.ordenes_compras = data.ordenes_compra;
          this.totalRecords = data.ordenes_compra.length;
          this.sucursales = data.sucursales;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    cargarProveedores() {
      this.proveedorService
        .getProveedores()
        .then((data) => {
          this.proveedores = data.proveedores || [];
        })
        .catch((error) => console.log(error));
    },
    nuevaCompra() {
      this.$router.push({
        name: "oc_recepcion",
        params: { id: "nueva_compra" },
      });
    },
    acciones(datos) {
      return [
        {
          label: "Ver OC",
          icon: "pi pi-fw pi-eye",
          disabled:
            "Compra Ver Detalle" in this.auth.user.permissions ? false : true,
          to: `/orden_compra/${datos.id}/detalle`,
        },
        {
          label: "Recibir OC",
          disabled: datos.estado != 1 ? true : false,
          visible:
            "Compra Recibir" in this.auth.user.permissions ? true : false,
          icon: "pi pi-fw pi-save",
          to: `/oc/${datos.id}/recibir`,
        },
        {
          label: "Descargar PDF",
          disabled:
            "Compra DescargarPDF" in this.auth.user.permissions ? false : true,
          icon: "pi pi-fw pi-file-pdf",
          command: () => {
            this.imprimirOC(datos.id);
          },
        },
        {
          label: "Anular OC",
          disabled: datos.estado != 1 ? true : false,
          visible: "Compra Anular" in this.auth.user.permissions ? true : false,
          icon: "pi pi-fw pi-times",
          command: () => {
            this.anularOC(datos.id);
          },
        },
        {
          label: "Pagar OC",
          disabled: datos.estado != 2 ? true : false,
          icon: "pi pi-fw pi-money-bill",
          command: () => {
            this.NuevoPagoProveedor(datos);
          },
          /* to: `/proveedorpagos`, */
        },
      ];
    },
    NuevoPagoProveedor(datos) {
      this.pago_proveedor = {
        ...datos,
        oc_recepcion_id: datos.oc_recepcion_id,
        id: datos.pago_proveedor_id,
      };
      this.pago_proveedor.oc_recepcion_id = datos.oc_recepcion_id;
      this.pagoProveedorDialog = true;
      this.pago_proveedor.fecha_pago = new Date();
      this.pago_proveedor.monto = this.pago_proveedor.saldo;
      this.pago_proveedor.observacion = "";
    },
    cerrarModalPagoProveedor() {
      this.pagoProveedorDialog = false;
    },
    anularOC(id) {
      this.$confirm.require({
        message: "¿Está seguro que desea anular la Orden de Compra?",
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, deseo Anular",
        accept: () => {
          this.ordenesCompras.anular(id).then((response) => {
            this.$toast.add({
              severity: "success",
              summary: "Éxito",
              detail: response.mensaje,
              life: 3000,
            });
            this.loading = true;
            this.cargarComprasFiltros();
          });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
        onHide: () => {
          //Callback to execute when dialog is hidden
        },
      });
    },
    imprimirOC(id) {
      this.ordenesCompras
        .imprimirOC(id)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    recibirOC(data) {
      this.$router.push(`/oc/${data.id}/recibir`);
    },
    /* cargarOrdenesCompras() {
      this.loading = true;
      setTimeout(() => {
        this.ordenesCompras
          .getAllOrdenesCompra({ dt_params: JSON.stringify(this.lazyParams) })
          .then((data) => {
            this.totalRecords = data.ordenes_compra.total;
            this.ordenes_compras = data.ordenes_compra.data || [];
            this.loading = false;
          });
      }, Math.random() * 1000 + 250);
    }, */
    activarModal() {
      this.NuevaCompra = true;
    },
    closeModal() {
      this.NuevaCompra = false;
      this.orden_compra = {};
    },
    verDetalle(orden_compra) {
      this.$router.push(`/orden_compra/${orden_compra.id}/detalle`);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
  border-radius: 5px;
  border: 1px solid #002236;
  padding: 10px;
}
</style>
